/* eslint-disable import/no-duplicates */
import {
  format,
  differenceInCalendarMonths,
  parseISO,
  isValid,
  formatDistance,
  addMinutes,
  formatISO,
} from 'date-fns';
import sampleLocale from 'date-fns/locale/en-US';
import { FieldNamesMarkedBoolean } from 'react-hook-form';

import { IErrorObject } from 'services/common/common.modal';
import { IAddress } from 'services/settings-service/settings.modal';

import i18n from '../i18n';

type TDateStyle =
  | 'short'
  | 'long'
  | 'ddMMMM'
  | 'time'
  | 'ddMMM'
  | 'longwithtime'
  | '24time'
  | 'longwithtimedd';

export const formatDate = (date: Date | string, type: TDateStyle, uppercase?: boolean): string => {
  let paramsDate = date as Date;

  if (!isValid(date)) {
    paramsDate = parseISO(paramsDate as unknown as string);
  }

  let formattedDate = '-';
  switch (type) {
    case 'short':
      formattedDate = format(paramsDate, 'dd MMM yyyy');
      break;
    case 'long':
      formattedDate = format(paramsDate, 'dd MMMM yyyy');
      break;

    case 'ddMMMM':
      formattedDate = format(paramsDate, 'dd MMMM');
      break;

    case 'ddMMM':
      formattedDate = format(paramsDate, 'dd MMM');
      break;

    case 'time':
      formattedDate = format(paramsDate, 'hh:mmaa');
      break;

    case '24time':
      formattedDate = format(paramsDate, 'HH:mm');
      break;

    case 'longwithtime':
      formattedDate = format(paramsDate, 'dd MMM yyy hh:mmaa');
      break;

    case 'longwithtimedd':
      formattedDate = format(paramsDate, 'dd MMM yyyy HH:mm:ss');
      break;
    default:
      formattedDate = format(paramsDate, 'dd MMMM yyy');
      break;
  }

  return uppercase ? formattedDate.toUpperCase() : formattedDate;
};

export const dateDiff = (date: Date): string => {
  return `${differenceInCalendarMonths(date, new Date())} ${i18n.t('common.months')}`;
};

function formatDistanceLocaleConverter(
  token: keyof typeof formatDistanceLocale,
  count: string,
  options: Record<string, unknown>,
): string {
  // eslint-disable-next-line no-param-reassign
  options = options || {};

  const formatDistanceLocale = {
    lessThanXSeconds: `{{count}} ${i18n.t('common.seconds')}`,
    xSeconds: `{{count}} ${i18n.t('common.seconds')}`,
    halfAMinute: `30 ${i18n.t('common.seconds')}`,
    lessThanXMinutes: `${i18n.t('common.lessThan')} {{count}} ${i18n.t('common.mins')}`,
    xMinutes: `{{count}} ${i18n.t('common.mins', { ns: 'translation' })}`,
    aboutXHours: `${i18n.t('common.about')} {{count}} ${i18n.t('common.hours')}`,
    xHours: `{{count}} ${i18n.t('common.hours')}`,
    xDays: `{{count}} ${i18n.t('common.days')}`,
    aboutXWeeks: `${i18n.t('common.about')} {{count}} ${i18n.t('common.weeks')}`,
    xWeeks: `{{count}} ${i18n.t('common.weeks')}`,
    aboutXMonths: `${i18n.t('common.about')} {{count}} ${i18n.t('common.months')}`,
    xMonths: `{{count}} ${i18n.t('common.months')}`,
    aboutXYears: `${i18n.t('common.about')} {{count}} ${i18n.t('common.years')}`,
    xYears: `{{count}} ${i18n.t('common.years')}`,
    overXYears: `${i18n.t('common.over')} {{count}} ${i18n.t('common.years')}`,
    almostXYears: `${i18n.t('common.almost')} {{count}} ${i18n.t('common.years')}`,
  };

  const result = formatDistanceLocale[token].replace('{{count}}', count);

  if (options.addSuffix) {
    return `${result} ${i18n.t('common.ago')}`;
  }

  return result;
}

export const calculateFullAge = (
  date: Date | string,
  helperText?: boolean,
  addSuffix?: boolean,
): string => {
  if (!date) return '-';

  let paramsDate = date as Date;

  if (!isValid(date)) {
    paramsDate = parseISO(paramsDate as unknown as string);
  }

  const ds = formatDistance(paramsDate, new Date(), {
    includeSeconds: !!helperText,
    addSuffix: !!addSuffix,
    locale: {
      ...sampleLocale,
      formatDistance: formatDistanceLocaleConverter,
    },
  });

  return ds;
};

export const booleanToText = (value: boolean): string => {
  return value ? 'yes' : 'no';
};

export const getURLParamByName = (param: string, search?: string): string | null => {
  const urlParams = new URLSearchParams(search ?? window.location.search);
  return urlParams.get(param);
};

export const addUrlId = (
  url: string | undefined,
  value: string | undefined | number,
  key?: string,
): string => {
  return url?.replace(`:${key ?? 'id'}`, String(value) ?? '') ?? '';
};

export const generateRandomColor = (): string => {
  const h = Math.floor(Math.random() * 360);
  const s = `${Math.floor(Math.random() * 100)}%`;
  const l = `${Math.floor(Math.random() * 60)}%`;

  return `hsl(${h},${s},${l})`;
};

export const getFullAddress = (address: IAddress): string => {
  const { line1, line2, locality, postcode, region, country } = address || {};
  return [line1, line2, locality, postcode, region, country].filter((data) => !!data).join(', ');
};

export const checkError = (errors: IErrorObject<Record<string, unknown>> | undefined): boolean => {
  if (!errors) return false;
  return Object.keys(errors).some((key) => !!errors[key]?.message);
};

export const isNumeric = (value: string): boolean => {
  return /^-?\d+$/.test(value);
};

export type CommonObj = Record<string, unknown>;

const isObjEmpty = (obj: CommonObj): boolean => !Object.keys(obj).length;
export const isObj = (el: unknown): boolean =>
  typeof el === 'object' && !Array.isArray(el) && el !== null;
const isArray = (el: unknown): boolean => Array.isArray(el);
const isBoolean = (el: unknown): boolean => typeof el === 'boolean';

export const removeEmpty = <T extends CommonObj>(obj: T, exclude?: string[]): T => {
  const excludeKeys = exclude ?? [];
  const keys = Object.keys(obj);

  return keys.reduce((acc, k) => {
    const el = obj[k];

    if (excludeKeys.includes(k)) {
      Reflect.set(acc, k, el);
      return acc;
    }

    if (isBoolean(el)) {
      Reflect.set(acc, k, el);
      return acc;
    }

    // object
    if (isObj(el)) {
      // date
      if (isValid(el)) {
        Reflect.set(acc, k, el);
        return acc;
      }
      // nested object
      const r = removeEmpty(el as T, excludeKeys);
      if (!isObjEmpty(r)) {
        Reflect.set(acc, k, r);
      }
      return acc;
    }
    // array
    if (isArray(el) && (el as Array<unknown>).length) {
      Reflect.set(acc, k, el);

      return acc;
    }

    if (isArray(el) && (el as Array<unknown>).length === 0) {
      return acc;
    }

    if (el || el === 0) {
      Reflect.set(acc, k, el);
    }
    return acc;
  }, {} as T);
};

export const mobileAndTabletCheck = (): boolean => {
  let check = false;
  // eslint-disable-next-line func-names
  (function (a): void {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor);

  return check || window.innerWidth <= 900;
};

export const getModuleIdsFromObject = (payload: unknown, keys: string[]): unknown => {
  const data = JSON.parse(JSON.stringify(payload)); // taking copy
  keys.forEach((key) => {
    (data as Record<string, unknown>)[key] =
      (((data as Record<string, unknown>)[key] as Record<string, unknown>)?.value as unknown) ??
      null;
  });

  return data;
};

export const parseJwt = (token: string): Record<string, string> => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

export type IDirtyFields = FieldNamesMarkedBoolean<Record<string, unknown>>;

export const dirtyValues = <T extends UnknownArrayOrObject>(
  dirtyFields: FieldNamesMarkedBoolean<Record<string, unknown>>,
  allValues: T,
): T => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])]),
  );
};

export const isFormDirty = (fields: IDirtyFields): boolean => !Object.keys(fields).length;

export const downloadFile = (data: string, fileName: string, type?: string): void => {
  const blob = new Blob([data], { type: type ?? 'text/csv' });

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');

  a.setAttribute('href', url);

  a.setAttribute('download', fileName);

  a.click();
};

export const copyToClipboard = (text: string): void => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};

export const numberWithCommas = (x: number | string): string => {
  return x.toLocaleString('en-US');
};

export function generateUniqueId(prefix = ''): string {
  return `${prefix}${Date.now().toString(36)}${Math.random().toString(36).substring(2, 15)}`;
}

export function removeUUID(input: string): string {
  // Regular expression to match UUIDs (version 1 to 5)
  const uuidRegex = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/g;

  // Replace all matches of the UUID pattern with an empty string
  return input.replace(uuidRegex, '');
}

export function convertLocalToUTC(localDate: Date): string {
  // Get the local time offset in minutes and convert the time to UTC
  const utcDate = addMinutes(localDate, localDate.getTimezoneOffset());

  // Convert the date to ISO format in UTC
  return formatISO(utcDate);
}
